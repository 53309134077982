<template>
  <v-container>
    <div>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
      <br />
      <h1 v-html="'Termina Corso'"></h1>
      <p class="text-secondary">
        Hai selezionato il corso con id {{ this.idCorso }}
      </p>
    </div>
    <v-card class="pb-6" v-if="loadingPage">
      <v-card-title v-html="'Riepilogo Lezioni'"></v-card-title>
      <v-main class="pb-0">
        <v-row class="pb-2">
          <v-col sm="3" md="3">
            <a class="v-btn" @click="getFileRiepilogoLezioni(0)">
              <v-icon> mdi-file-excel</v-icon>
              <span> Esporta su Excel</span>
            </a>
          </v-col>
          <v-col sm="3" md="3">
            <a class="v-btn" @click="getFileRiepilogoLezioni(1)">
              <span>
                <v-icon> mdi-file-pdf-box </v-icon>
                Esporta su PDF
              </span></a
            >
          </v-col>
        </v-row>
      </v-main>
      <template>
        <div class="my-6">
          <StudentiDettaglioComponent
            :capofila="this.capofila"
            :id-corso="this.idCorso"
            :finestra-compilazione-attiva="finestraCompilazioneAttiva"
            @inAttesa="inAttesaCallback"
          ></StudentiDettaglioComponent>
        </div>
      </template>
      <v-main class="pt-10" v-if="lezioniCorso && lezioniCorso.length > 0">
        <v-data-table :headers="tableLezioni" :items="lezioniCorso">
          <!-- eslint-disable -->
          <template v-slot:item.data="{ item }">
            <span> {{ convertData(item.data) }}</span>
          </template>
          <template v-slot:item.inPresenza="{ item }">
            <span> {{ item.inPresenza ? "Si" : "No" }}</span>
          </template>
          <template v-slot:item.docenti="{ item }">
            <span v-html="getElencoDocenti(item.docenti)"></span>
          </template>
          <template v-slot:item.azioni="{ item, index }">
            <v-dialog v-model="dialog[index]">
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on="on"
                  @click.self="showDialog(index, true)"
                >
                  Visualizza Riepilogo
                </a>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Dettaglio Studenti
                </v-card-title>
                <v-data-table
                  :headers="tableStudenti"
                  :items="item.lezioniStudenti"
                  item-key="cf"
                >
                  <template v-slot:item.nomeCognome="{ item }">
                    <span>
                      {{ item.studente.nome }} {{ item.studente.cognome }}</span
                    >
                  </template>
                  <template v-slot:item.studente.dataNascita="{ item }">
                    <span> {{ convertData(item.studente.dataNascita) }}</span>
                  </template>
                  <template v-slot:item.istituto="{ item }">
                    <span v-html="getNomeIstituto(item)"></span>
                  </template>
                </v-data-table>
                <v-card-actions>
                  <v-btn
                    @click="showDialog(index, false)"
                    class="primary"
                    width="300"
                  >
                    CHIUDI
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- eslint-enable -->
        </v-data-table>
        <v-card-actions>
          <v-main v-if="!corso.terminato">
            <v-form>
              <v-btn
                v-if="finestraCompilazioneAttiva"
                @click="showFirstDialogConferma(true)"
                color="primary"
                :disabled="controlloBottoneTerminaDisabilitato.value"
              >
                Termina Corso</v-btn
              >
              <dialog-conferma
                @callback="showSecondDialogConferma(true)"
                :dialog.sync="paramsDialogConferma1.dialogConferma1"
                @close-dialog="showFirstDialogConferma(false)"
                v-model="paramsDialogConferma1.dialogConferma1"
                :title="paramsDialogConferma1.title"
                :text-conferma="paramsDialogConferma1.subtitle"
              />
              <dialog-conferma
                @callback="onSubmitTerminaCorso"
                :dialog.sync="paramsDialogConferma2.dialogConferma2"
                @close-dialog="showSecondDialogConferma(false)"
                v-model="paramsDialogConferma2.dialogConferma2"
                :title="paramsDialogConferma2.title"
                :text-conferma="paramsDialogConferma2.subtitle"
              />
            </v-form>
          </v-main>
          <v-main v-else>
            <v-alert border="top" colored-border type="info" elevation="2">
              Il corso è terminato in data
              {{ formatDate(corso.dataTermineCorso) }}.
            </v-alert>
          </v-main>

          <v-main
            v-if="controlloBottoneTerminaDisabilitato.value && !corso.terminato"
          >
            <v-alert density="compact" type="warning">
              <h4>
                Impossibile terminare il corso per le seguenti motivazioni:
              </h4>
              <ul>
                <li
                  v-for="(
                    errori, index
                  ) in controlloBottoneTerminaDisabilitato.arrayError"
                  :key="index"
                >
                  {{ errori }}
                </li>
              </ul>
              <!---<p>
                Il campo "Numero docenti" del form di compilazione corso risulta
                vuoto. Tornare alla sezione "Censimento corsi", e aggiornare il
                campo richiesto
              </p>--></v-alert
            >
          </v-main>
        </v-card-actions>
      </v-main>
    </v-card>
    <v-card v-else>
      <v-progress-linear indeterminate color="blue"></v-progress-linear>
    </v-card>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import DialogConferma from "@/components/DialogConferma";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import { mapState } from "vuex";
import StudentiDettaglioComponent from "@/components/StudentiDettaglio";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "terminaCorso",
  components: {
    StudentiDettaglioComponent,
    BreadcrumbComponent,
    DialogConferma,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idCorso: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["anno", "idStrutture"]),
    breadcrumbs: function () {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania/${this.capofila}`,
          exact: true,
          link: true,
        },
        {
          text: "Censimento, erogazione e validazione corsi",
          disabled: false,
          to: `/scrivania/${this.capofila}/corsi`,
          exact: true,
          link: true,
        },
        {
          text: "Erogazione corsi",
          disabled: false,
          to: `/scrivania/${this.capofila}/corsi/erogazione-corsi`,
          exact: true,
          link: true,
        },
        {
          text: "Termina Corso",
          disabled: true,
          to: `/scrivania/${this.capofila}/corsi/erogazione-corsi/${this.idCorso}/termina`,
          exact: true,
          link: true,
        },
      ];
    },
    loading() {
      return this.loadingPage;
    },
  },
  watch: {
    idCorso() {
      return this.initialize();
    },
    capofila() {
      return this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  data: () => ({
    finestraCompilazione: null,
    finestraCompilazioneAttiva: true,
    inAttesa: false,
    lezioniCorso: null,
    corso: null,
    loadingPage: false,
    dialog: [],
    errori: false,
    tableLezioni: [
      {
        text: "DATA LEZIONE",
        align: "start",
        value: "data",
        sortable: false,
      },
      { text: "ORE EROGATE", value: "oreErogate" },
      { text: "LEZIONE IN PRESENZA", value: "inPresenza" },
      { text: "DOCENTI ATENEO / ISTITUTO-AFAM", value: "docenti" },
      { text: "AZIONI", value: "azioni" },
    ],
    tableStudenti: [
      {
        text: "NOME E COGNOME",
        align: "start",
        value: "nomeCognome",
        sortable: false,
      },
      { text: "DATA DI NASCITA", value: "studente.dataNascita" },
      { text: "LUOGO DI NASCITA", value: "studente.comuneNascitaDescr" },
      { text: "COMUNE DI RESIDENZA", value: "studente.comuneResidenzaDescr" },
      { text: "CODICE FISCALE", value: "studente.cf" },
      { text: "GENERE", value: "studente.genere.descrizione" },
      { text: "ISTITUTO", value: "istituto" },
      { text: "ORE SVOLTE", value: "oreSvolte" },
    ],
    valueStudentiDialog: [],
    paramsDialogConferma1: {
      title: "",
      subtitle:
        "Confermi di aver inserito correttamente i dati sul genere delle persone associate al corso?",
      dialogConferma1: false,
    },
    paramsDialogConferma2: {
      title: "Confermi di voler terminare il corso?",
      subtitle:
        "Stai per trasmettere la richiesta di chiusura corso. Sei sicuro/a di voler terminare quest'azione?",
      dialogConferma2: false,
    },
    controlloBottoneTerminaDisabilitato: { value: false, arrayError: [] },
    controlloValiditaLezioni: true,
  }),
  methods: {
    async initialize() {
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;
      let idCorso = this.idCorso;
      this.getFinestraCompilazione(idStrutture, anno);
      if (idCorso) {
        await this.getCorso(idStrutture, anno, idCorso);
        this.studentiConvalidatiCorso();
      }
    },
    async getFinestraCompilazione(idStrutture, anno) {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          idStrutture,
          anno,
          "corsi-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
      this.finestraCompilazioneAttiva = !!this.finestraCompilazione;
    },
    async getLezioniCorso(idStrutture, idCorso) {
      CorsiRepository.getLezioniCorso(idStrutture, idCorso).then((data) => {
        if (data && data.length > 0) {
          this.lezioniCorso = data;
          this.lezioniCorso.forEach((item, index) => {
            this.dialog[index] = false;
            if (
              item.docenti.length === 0 //||
              //item.lezioniStudenti.length === 0 ->PROGMINI-5117 possibilità di terminare corso anche se lezioni = 0 studenti registrati
            ) {
              this.controlloValiditaLezioni = false;
            } else if (
              item.lezioniStudenti.length === 0 &&
              this.corso.corsiAssociati &&
              this.corso.corsiAssociati.length < 1
            ) {
              this.controlloValiditaLezioni = false;
            }
          });
        } else {
          this.lezioniCorso = [];
        }
        this.verificaBottoneTerminaDisabilitato();
      });
    },
    async getRiepilogoLezioni(idStrutture, idCorso) {
      CorsiRepository.getRiepilogoLezioni(idStrutture, idCorso).then((data) => {
        this.riepilogoGeneraleLezioni = data;
      });
    },
    convertData: function (data) {
      return new Date(data).toLocaleDateString();
    },
    getElencoDocenti(docenti) {
      if (docenti && docenti.length > 0) {
        let textDocenti = "";
        docenti.forEach(function (item, index) {
          let stringDocente = item.nome + " " + item.cognome;
          index !== docenti.length - 1
            ? (stringDocente += ", ")
            : (stringDocente += ".");
          textDocenti += stringDocente;
        });
        return textDocenti;
      } else {
        return "Nessun docente segnato in questa lezione";
      }
    },
    getCorso(idStrutture, anno, idCorso) {
      CorsiRepository.getCorsoOrientamento(idStrutture, anno, idCorso).then(
        (data) => {
          if (data) {
            this.corso = data;
            this.getLezioniCorso(idStrutture, idCorso);
          }
        }
      );
    },
    onSubmitTerminaCorso() {
      let idStrutture = this.$store.state.idStrutture;
      let idCorso = this.idCorso;
      CorsiRepository.terminaCorso(idStrutture, idCorso).then((data) => {
        if (data) {
          this.$router.push({
            name: "erogazioneCorsi",
            params: { capofila: this.capofila },
          });
        }
      });
    },
    showDialog(index, value) {
      this.$set(this.dialog, index, value);
    },
    showFirstDialogConferma(value) {
      this.$set(this.paramsDialogConferma1, "dialogConferma1", value);
      //this.paramsDialogConferma1.dialogConferma1 = true;
    },
    showSecondDialogConferma(value) {
      this.$set(this.paramsDialogConferma2, "dialogConferma2", value);
      //this.paramsDialogConferma2.dialogConferma2 = true;
    },
    async getFileRiepilogoLezioni(tipoFile) {
      let idStrutture = this.$store.state.idStrutture;
      let lezioniCorso = this.lezioniCorso.map((item) => {
        let lezione = item;
        delete lezione.corso;
        return lezione;
      });
      lezioniCorso = { ...this.lezioniCorso };
      const formData = new FormData();
      formData.append("lezioni", JSON.stringify(lezioniCorso));
      await RichiesteRepository.downloadFileRiepilogoLezioni(
        idStrutture,
        this.idCorso,
        tipoFile,
        formData
      );
    },
    inAttesaCallback(inAttesa) {
      this.inAttesa = inAttesa;
      this.verificaBottoneTerminaDisabilitato();
    },
    async studentiConvalidatiCorso() {
      this.errori = await CorsiRepository.getStudentiConvalidatiCorso(
        this.idStrutture,
        this.idCorso,
        this.anno
      );
    },
    verificaBottoneTerminaDisabilitato() {
      let res = {
        value: false,
        arrayError: [],
      };
      // controllo numero 1, il corso
      if (this.corso.numeroDocenti == null) {
        res.value = true;
        res.arrayError.push(
          " Il campo 'Numero Docenti' del form di compilazione corso risulta vuoto."
        );
      }

      /* controllo 2, la variabile che verifica se tutte le lezioni hanno studenti e docenti assegnati.
         True condizione soddisfatta, false condizione non soddisfatta e il bottone disabilitato
      */
      if (!this.controlloValiditaLezioni) {
        res.value = true;
        res.arrayError.push(
          "Una o più lezioni del corso non ha docenti o studenti assegnati."
        );
      }
      // controllo numero 3, errori nei cf degli studenti
      if (this.errori) {
        res.value = true;
        res.arrayError.push(
          "Sono presenti errori nei codici fiscali degli studenti"
        );
      }
      // controllo numero 4, cf degli studenti in attesa di convalida
      if (this.inAttesa) {
        res.value = true;
        res.arrayError.push(
          "Sono presenti codici fiscali degli studenti in attesa di convalida"
        );
      }
      // controllo numero 5, pdf accordo caricato
      if (!this.isAccordiFirmati()) {
        res.value = true;
        res.arrayError.push(
          "Non sono stati caricati i pdf di uno o più accordi associati al corso"
        );
      }
      //Nuovi controlli anno 2024/26
      if (this.anno > 2023) {
        //controllo numero 6: Vincolo Data di Firma accordo: Non è possibile terminare un corso nel caso in cui la data di inizio del corso  è < della data di firma dell'accordo
        let dataFirmaAccordo = this.getDataMinFirmaAccordo(this.corso.accordi);
        if (!dataFirmaAccordo) {
          res.value = true;
          res.arrayError.push(
            "Non è possibile terminare il corso perché la data di firma dell'accordo non è stata inserita"
          );
        }
        const dataInizioCorso = new Date(this.corso.dataInizio);
        const dataFirmaAccordoObj = new Date(dataFirmaAccordo);
        if (dataInizioCorso < dataFirmaAccordoObj) {
          res.value = true;
          res.arrayError.push(
            "Non è possibile terminare il corso perché la data di inizio del corso è antecedente la data di firma dell'accordo. Si prega di correggere i dati nella sezione del censimento corso. Nel caso in cui siano state registrate lezioni prima della di firma dell'accordo è necessario provvedere alla loro correzione. "
          );
        }
        //controllo numero 7: Vincolo data inizio accordo: Non è possibile terminare un corso nel caso in cui la data di fine del corso sia > della data di fine dell'accordo
        let dataFineAccordo = this.getDataMinFineAccordo(this.corso.accordi);
        if (!dataFineAccordo) {
          res.value = true;
          res.arrayError.push(
            "Non è possibile terminare il corso perché la data di fine dell'accordo non è stata inserita"
          );
        }
        const dataFineCorso = new Date(this.corso.dataFine);
        const dataFineAccordoObj = new Date(dataFineAccordo);
        if (dataFineCorso > dataFineAccordoObj) {
          res.value = true;
          res.arrayError.push(
            "Non è possibile terminare il corso perché la data di fine del corso è successiva alla data di fine dell'accordo. Si prega di correggere i dati nella sezione del censimento corso. Nel caso in cui siano state registrate lezioni oltre la data di fine dell'accordo è necessario provvedere alla loro correzione."
          );
        }
      }

      if (res.value) {
        this.controlloBottoneTerminaDisabilitato = res;
      }
      this.loadingPage = true;
    },
    getDataMinFirmaAccordo(accordi) {
      if (accordi.length > 1) {
        accordi.reduce((minData, accordo) => {
          const dataCorrente = new Date(accordo.dataFirmaAccordo);
          return dataCorrente < minData ? dataCorrente : minData;
        }, new Date(accordi[0].dataFirmaAccordo));
      } else {
        return accordi[0].dataFirmaAccordo ?? null;
      }
    },
    getDataMinFineAccordo(accordi) {
      if (accordi.length > 1) {
        accordi.reduce((minData, accordo) => {
          const dataCorrente = new Date(accordo.dataFineAccordo);
          return dataCorrente < minData ? dataCorrente : minData;
        }, new Date(accordi[0].dataFineAccordo));
      } else {
        return accordi[0].dataFineAccordo ?? null;
      }
    },
    getNomeIstituto(corso) {
      if (corso.accordi && corso.accordi.length > 0) {
        let testo = "";
        corso.accordi.forEach((item) => {
          testo += item.istituto.denominazioneScuola + "/<br />";
        });
        return testo;
      } else {
        return corso.accordo.istituto.denominazioneScuola;
      }
    },
    isAccordiFirmati() {
      return this.corso.accordi.every((accordo) => "fileAccordo" in accordo);
    },
    formatDate(data) {
      return new Date(data).toLocaleDateString("it-IT");
    },
  },
};
</script>
